<template>
  <div class="container">
    <section class="section">
      <h2 class="subtitle">
        Her kan det være en beskrivelse av hva poststempel-databasen er, og hvordan man får tilgang.
      </h2>
    </section>
  </div>
</template>

<script>
import UserService from '../services/user.service';

export default {
  name: 'BoardHome',
  data() {
    return {
      content: ''
    };
  },
  mounted() {
/*    UserService.getPublicContent().then(
      response => {
        this.content = response.data;
      },
      error => {
        this.content =
          (error.response && error.response.data && error.response.data.message) ||
          error.message ||
          error.toString();
      }
    );*/
  }
};
</script>
