import Vue from 'vue'
import App from './App.vue'
import { router } from './router';
import store from './store'
import Buefy from 'buefy'
import VeeValidate from 'vee-validate';
import vueMoment from 'vue-moment';
import VueMq from 'vue-mq';
import Vuex from 'vuex';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faAnglesLeft,
  faAnglesRight,
  faCircleCheck,
  faCircleInfo,
  faMagnifyingGlassPlus
} from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faAnglesLeft, faAnglesRight, faCircleCheck, faCircleInfo, faMagnifyingGlassPlus);

Vue.use(Buefy);
Vue.use(vueMoment);
Vue.use(Vuex);
Vue.use(VeeValidate);

Vue.use(VueMq, {
  breakpoints: {
    mobile: 450,
    tablet: 900,
    laptop: 1250
  }
})

Vue.config.productionTip = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
