import Vue from 'vue';
import Router from 'vue-router';
import BoardHome from './views/BoardHome.vue';
import LoginCard from './views/Login.vue';
import RegisterCard from './views/Register.vue';

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: BoardHome
    },
    {
      path: '/home',
      component: BoardHome
    },
    {
      path: '/login',
      component: LoginCard
    },
    {
      path: '/register',
      component: RegisterCard
    },
    {
      path: '/profile',
      name: 'profile',
      // lazy-loaded
      component: () => import('./views/BoardProfile.vue')
    },
    {
      path: '/admin',
      name: 'admin',
      // lazy-loaded
      component: () => import('./views/BoardAdmin.vue')
    },
    {
      path: '/user',
      name: 'user',
      // lazy-loaded
      component: () => import('./views/BoardUser.vue')
    },
    {
      path: '/mod',
      name: 'moderator',
      // lazy-loaded
      component: () => import('./views/BoardModerator.vue')
    },
    {
      path: '/cancel',
      name: 'cancel',
      // lazy-loaded
      //component: () => import('./views/BoardUser.vue')
      //component: () => import('./App_org.vue')
      component: () => import('./components/TheSearchbar.vue')
    }
  ]
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login', '/register', '/home'];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem('user');

//   // trying to access a restricted page + not logged in
//   // redirect to login page
//   if (authRequired && !loggedIn) {
//     next('/login');
//   } else {
//     next();
//   }
// });
