<template>
  <div id="app">
    <nav class="navbar is-gradient" role="navigation" aria-label="main navigation">
      <div class="container">
        <div class="navbar-brand is-inline-flex-mobile is-inline-flex-tablet">
          <h2 class="is-size-3-desktop is-size-4-mobile">
            <router-link to="/home" class="navbar-item">
              Norske Poststempler
            </router-link>
          </h2>
          <div v-if="currentUser" style="margin:auto">
            <router-link to="/cancel" class="navbar-item">
              Søk poststempler
            </router-link>
          </div>
          <div v-if="showAdminBoard" style="margin:auto">
            <router-link to="/admin" class="navbar-item">
              Godkjenn stempler
            </router-link>
          </div>
          <div v-if="!showAdminBoard && showModeratorBoard" style="margin:auto">
            <router-link to="/mod" class="navbar-item">
              Mine stempelendringer
            </router-link>
          </div>
          <div v-if="showAdminBoard" style="margin:auto">
            <router-link to="/user" class="navbar-item">
              Brukeradministrasjon
            </router-link>
          </div>
        </div>

        <div v-if="!currentUser" class="navbar-end is-inline-flex-mobile is-inline-flex-tablet">
          <router-link to="/login" class="navbar-item navbar-custom">
            <font-awesome-icon icon="sign-in-alt" />Logg inn
          </router-link>
        </div>

        <div v-if="currentUser" class="navbar-end is-inline-flex-mobile is-inline-flex-tablet">
          <router-link to="/profile" class="navbar-item navbar-custom">
            {{ currentUser.username }}
          </router-link>
          <router-link v-if="showAdminBoard" to="/register" class="navbar-item navbar-custom">
            <font-awesome-icon icon="user-plus" />Registrer
          </router-link>
          <a class="navbar-item navbar-custom" href @click.prevent="logOut">
            <font-awesome-icon icon="sign-out-alt" />Logg ut
          </a>
        </div>
      </div>
    </nav>

    <router-view />
  </div>
</template>

<script>
export default {
  computed: {
    currentUser () {
      return this.$store.state.auth.user
    },
    showAdminBoard () {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN')
      }

      return false
    },
    showModeratorBoard () {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_EDITOR')
      }

      return false
    }
  },
  methods: {
    logOut () {
      this.$store.commit('CLEAR_SEARCH')
      this.$store.dispatch('auth/logout')
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="scss">
  // Import Bulma's core
  @import "~bulma/sass/utilities/_all";

  // Set your colors
  $primary: #cf6132;
  $primary-invert: findColorInvert($primary);

  // Setup $colors to use as bulma classes (e.g. 'is-twitter')
  $colors: (
      "white": ($white, $black),
      "black": ($black, $white),
      "light": ($light, $light-invert),
      "dark": ($dark, $dark-invert),
      "primary": ($primary, $primary-invert),
      "info": ($info, $info-invert),
      "success": ($success, $success-invert),
      "warning": ($warning, $warning-invert),
      "danger": ($danger, $danger-invert),
  );

  // Links
  $link: $primary;
  $link-invert: $primary-invert;
  $link-focus-border: $primary;

  // Import Bulma and Buefy styles
  @import "~bulma";
  @import "~buefy/src/scss/buefy";

  // transitions
  .list-enter-active,
  {
    transition: all .5s;
  }
  .list-leave-active {
    transition: all .5s;
  }
  .list-enter,
  .list-leave-to {
    opacity: 0;
     transform: translateY(20px);
  }
  .fade-enter-active, .component-fade-leave-active {
    transition: opacity .3s ease;
  }
  .fade-enter, .component-fade-leave-to
  {
    opacity: 0;
  }

  a.dropdown-item {
    padding-right: 1rem ;
    border-bottom: 1px solid #ccc;
  }
  img {
    max-height: 130px !important;
  }
  .navbar-item {
    color: #fff !important;
  }

  .navbar-custom.navbar-item:hover,
  .navbar-custom.navbar-item:focus,
  .navbar-custom.navbar-item:focus-within {
    background: none;
  }

  .badge {
    position: absolute;
      right: -2px;
      top: 4px;
      display: inline-block;
      min-width: 19px;
      padding: 2px 6px;
      font-size: 10px;
      font-weight: bold;
      color: #FFF;
      line-height: 1.43;
      vertical-align: text-top;
      white-space: nowrap;
      text-align: center;
      text-shadow: none;
      background-clip: padding-box;
      background-color: #EC5252;
      border: none;
      border-radius: 15px;
      z-index: 1;
  }
  .nav-icon {
    position: relative;
    margin-left: 10px
  }
  .fas {
    color: #ccc;
  }
  .fas:hover {
    color: #fff;
  }
  .icon-inactive {
    color: #ccc;
  }
  .icon-active {
    color: $primary !important;
  }
  .is-gradient {
    background-color: #946000 !important;
    background-image: linear-gradient(326deg, #f46c44, #4e2d0494  94%) !important;
  }

  .navbar-end {
      -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    font-size: 1.25rem;
    font-weight: 400;
  }
</style>
