import axios from 'axios'
import authHeader from './../services/auth-header'

// import { Snackbar } from 'buefy/dist/components/snackbar'

export const appstate = {
  state: {
    settings: { initialSearchQuery: '', searchQuery: '', panelType: 'media', bookmarkIcon: 'fa-edit', perPage: '6', youtubeLink: 'false' },
    cancels: [],
    cancelTracks: {},
    bookmarkCancels: [],
    searchFailed: false,
    cancelTracksFailed: false,
    recentSearch: [],
    showRecentSearchBox: false,
    isCancelLoading: false,
    isEditingCancel: false,
    isCancelTracksLoading: false,
    language: 'en_us',
    pageType: 'search',
    currentPage: 1,
    totalCancels: 0,
    appError: null
  },
  getters: {
    SEARCH_QUERY: (state) => {
      return state.settings.searchQuery
    },
    INITIAL_SEARCH_QUERY: (state) => {
      return state.settings.initialSearchQuery
    },
    GET_CANCELS: (state) => {
      return state.cancels
    },
    GET_CANCEL_TRACKS: (state) => {
      return state.cancelTracks
    },
    GET_RECENT_SEARCH: (state) => {
      if (state.recentSearch.length === 0 && localStorage.getItem('recent_search') !== null) {
        state.recentSearch = JSON.parse(localStorage.getItem('recent_search'))
      }
      return state.recentSearch
    },
    IS_CANCEL_LOADING: (state) => {
      return state.isCancelLoading
    },
    IS_EDITING_CANCEL: (state) => {
      return state.isEditingCancel
    },
    IS_CANCEL_TRACKS_LOADING: (state) => {
      return state.isCancelTracksLoading
    },
    SEARCH_FAILED: (state) => {
      return state.searchFailed
    },
    CANCEL_TRACKS_FAILED: (state) => {
      return state.cancelTracksFailed
    },
    BOOKMARK_CANCELS: (state) => {
      return state.bookmarkCancels.reverse()
    },
    PAGE_TYPE: (state) => {
      return state.pageType
    },
    CURRENT_PAGE: (state) => {
      return state.currentPage
    },
    TOTAL_CANCELS: (state) => {
      return state.totalCancels
    },
    SHOW_RECENT_SEARCH_BOX: (state) => {
      return state.showRecentSearchBox
    },
    GET_SETTINGS: (state) => {
      return state.settings
    }
  },
  mutations: {
    SET_SEARCH_QUERY: (state, query) => {
      state.pageType = 'search'
      state.settings.searchQuery = query
    },
    SET_CANCEL: (state, data) => {
      state.cancels = data.postalCancels
      state.currentPage = (data.currentPage + 1)
      state.totalCancels = data.totalCancels
    },
    SET_CANCEL_TRACKS: (state, data) => {
      state.cancelTracks = data
    },
    SEARCH_FAILED: (state, action) => {
      state.searchFailed = action
    },
    SET_RECENT_SEARCH: (state, data) => {
      state.pageType = 'search'
      state.recentSearch = data
    },
    CLEAR_SEARCH: (state) => {
      state.cancels = []
      state.settings.searchQuery = ''
    },
    TOGGLE_RECENT_SEARCH: (state) => {
      state.showRecentSearchBox = !state.showRecentSearchBox
    },
    SET_BOOKMARK_CANCELS: (state, cancels) => {
      state.bookmarkCancels = cancels
    },
    IS_CANCEL_LOADING: (state, action) => {
      state.isCancelLoading = action
    },
    IS_EDITING_CANCEL: (state, action) => {
      state.isEditingCancel = action
    },
    IS_CANCEL_TRACKS_LOADING: (state, action) => {
      state.isCancelTracksLoading = action
    },
    SET_PAGE_TYPE: (state, type) => {
      if (type === 'bookmarks') { state.settings.searchQuery = '' }
      state.pageType = type
    },
    SET_SETTINGS: (state, settings) => {
      state.settings = settings
    },
    SET_CANCEL_TRACKS_FAILED: (state, action) => {
      state.cancelTracksFailed = action
      state.cancelTracks = []
    },
    RESET_CANCEL_TRACKS: (state) => {
      state.cancelTracks = []
    },
    APP_ERROR: (state, message) => {
      state.appError = message
      /* Snackbar.open({
        message: message,
        type: 'is-danger',
        position: 'is-top',
        actionText: 'Reload App',
        indefinite: true,
        onAction: () => {
          window.location.reload()
        }
      }) */
    }
  },
  actions: {
    SEARCH_CANCELS: async ({ commit, dispatch }, payload) => {
      try {
        // show loading animation
        commit('IS_CANCEL_LOADING', true)
        const { data } = await axios.get(`${payload.url}`, { headers: authHeader() })
        if (data.length === 0) {
          // if search response data results is empty commit search failed and clear the search input
          commit('CLEAR_SEARCH')
          commit('SEARCH_FAILED', true)
          commit('IS_CANCEL_LOADING', false)
        } else {
          // assign the search data results to set cancel state and query to set search query state
          commit('IS_CANCEL_LOADING', false)
          commit('SEARCH_FAILED', false)
          commit('SET_CANCEL', data)
          commit('SET_SEARCH_QUERY', payload.query)
          dispatch('SAVE_TO_RECENT_SEARCH', payload.query)
        }
      } catch (err) {
        // if error commit search failed and clear the search input
        commit('CLEAR_SEARCH')
        commit('SEARCH_FAILED', true)
        commit('IS_CANCEL_LOADING', false)
        commit('APP_ERROR', err.message)
      }
    },
    SAVE_TO_RECENT_SEARCH: ({ commit }, payload) => {
      try {
        let recentSearch = []
        // check if localstorage have recent search datas
        if (localStorage.getItem('recent_search') === null) {
          // if localstorage is null push the new recent search to empty array
          recentSearch.push(payload)
          localStorage.setItem('recent_search', JSON.stringify(recentSearch))
        } else {
          // if localstorage is not null append the new recent search to the end of array
          recentSearch = JSON.parse(localStorage.getItem('recent_search'))
          recentSearch.push(payload)
          // check if the new recent search is already in the array then remove duplicate and save to localstorage
          let newRecentSearch = (recentSearch) = recentSearch.filter((item, i) => recentSearch.indexOf(item) === i)
          localStorage.setItem('recent_search', JSON.stringify(newRecentSearch))
        }
        // assign a new array to the set recent search state
        commit('SET_RECENT_SEARCH', recentSearch)
      } catch (err) {
        commit('APP_ERROR', err.message)
      }
    },
    GET_RECENT_SEARCH: ({ commit }) => {
      try {
        // assign recent_search localstorage dato to a recentSearch variable
        const recentSearch = localStorage.getItem('recent_search')
        // check if recentSearch variable is not null
        if (recentSearch !== null) {
          // if not null assign a new array to the  recentSearch state
          commit('SET_RECENT_SEARCH', JSON.parse(recentSearch))
        }
      } catch (err) {
        commit('APP_ERROR', err.message)
      }
    },
    REMOVE_RECENT_SEARCH_ITEM: ({ commit }, item) => {
      try {
        // assign recent_search localstorage to recentSearchItems variable
        const recentSearchItems = JSON.parse(localStorage.getItem('recent_search'))
        // get the index location of the item in recentSearchItems array
        const recentSearchItemsIndex = recentSearchItems.indexOf(item)
        // remove if item is in the recentSearchItems array
        if (recentSearchItemsIndex !== -1) recentSearchItems.splice(recentSearchItemsIndex, 1)
        // assign the new recentSearchItems array to the recent_search localstorage
        localStorage.setItem('recent_search', JSON.stringify(recentSearchItems))
        // if recentSearchItems is empty remove recent search box
        if (recentSearchItems.length === 0) {
          commit('TOGGLE_RECENT_SEARCH')
        }
        // assign the new recentSearchItems array to the the set recent search state
        commit('SET_RECENT_SEARCH', recentSearchItems)
      } catch (err) {
        commit('APP_ERROR', err.message)
      }
    },
    BOOKMARK_CANCEL: ({ commit }, payload) => {
      try {
        // destructure and assign payload cancel objects to the new variables
        const { artistName, collectionCensoredName, artworkUrl100, primaryGenreName, collectionViewUrl, collectionId } = payload.cancel
        // assign the new payload cancel variables as object items to newBookmarkItem variable
        const newBookmarkItem = { artistName, collectionCensoredName, artworkUrl100, primaryGenreName, collectionViewUrl, collectionId }
        let bookmarkCancels = []
        // check payload status
        if (payload.status === 'unbookmarked') {
          // if status is unbookmarked assign bookmark_cancels localstorage to boolmarkCancels
          bookmarkCancels = JSON.parse(localStorage.getItem('bookmark_cancels'))
          // check if the bookmarkCancels item is already in the array
          const oldBookmarkCancels = bookmarkCancels.map((e) => { return e.collectionCensoredName }).indexOf(collectionCensoredName)
          // if is in the array remove payload item to bookmarkCancels
          if (oldBookmarkCancels !== -1) bookmarkCancels.splice(oldBookmarkCancels, 1)
          // set the new bookmarkCancels array to the localstorage
          localStorage.setItem('bookmark_cancels', JSON.stringify(bookmarkCancels))
        } else {
          // if status is bookmark
          // check if bookmark storage is null
          if (localStorage.getItem('bookmark_cancels') === null) {
            // push the newBookmarkItem to bookmarkCancels
            bookmarkCancels.push(newBookmarkItem)
            // set the new bookmarkCancels array to the localstorage
            localStorage.setItem('bookmark_cancels', JSON.stringify(bookmarkCancels))
          } else {
            // if bookmark storage have datas
            // assign bookmark_cancel localstorage data to bookmarkCancels
            bookmarkCancels = JSON.parse(localStorage.getItem('bookmark_cancels'))
            // push the newBookmarkItem to bookmarkCancels
            bookmarkCancels.push(newBookmarkItem)
            // push the newBookmarkItem to bookmarkCancels
            localStorage.setItem('bookmark_cancels', JSON.stringify(bookmarkCancels))
          }
        }
        commit('SET_BOOKMARK_CANCELS', bookmarkCancels)
      } catch (err) {
        commit('APP_ERROR', err.message)
      }
    },
    GET_BOOKMARK_CANCELS: ({ commit }) => {
      try {
        // assign bookmark_cancels localstorage to bookmarkCancels variable
        const bookmarkCancels = localStorage.getItem('bookmark_cancels')
        if (bookmarkCancels !== null) {
          // if not null assign the new bookmark cancels array to the bookmark cancels state
          commit('SET_BOOKMARK_CANCELS', JSON.parse(bookmarkCancels))
        }
      } catch (err) {
        commit('APP_ERROR', err.message)
      }
    },
    GET_SETTINGS: ({ commit, state }) => {
      try {
        // assign settings localstorage to settings variable
        const settings = localStorage.getItem('settings')
        // check if settings variable is not null
        if (settings !== null) {
          // if not null assign the new setting array to the setting state
          commit('SET_SETTINGS', JSON.parse(settings))
        } else {
          // if null set the default state settings to localstorage
          localStorage.setItem('settings', JSON.stringify(state.settings))
        }
      } catch (err) {
        commit('APP_ERROR', err.message)
      }
    },
    UPDATE_SETTINGS: ({ commit, state }, payload) => {
      try {
      // destructure and assign payload objects and state to the new variables
        const { settingValue, settingName } = payload
        const { settings } = state
        // update settings by reassigning the settings object name to the payload object name
        settings[settingName] = settingValue
        // assign the new settings array to the settings state
        commit('SET_SETTINGS', settings)
        // assign the new settings array to the settings localstorage
        localStorage.setItem('settings', JSON.stringify(settings))
      } catch (err) {
        commit('APP_ERROR', err.message)
      }
    },
    GET_CANCEL_TRACKS: async ({ commit, state }, payload) => {
      try {
        // show loading animation
        commit('IS_CANCEL_TRACKS_LOADING', true)
        // reset cancel tracks
        if (state.cancelTracks.length > 0) {
          commit('RESET_CANCEL_TRACKS')
        }
        const { data } = await axios.get(`${payload.url}`, { headers: authHeader() })
        if (data.length === 0) {
          commit('SET_CANCEL_TRACKS_FAILED', true)
          commit('IS_CANCEL_TRACKS_LOADING', false)
        } else {
          commit('SET_CANCEL_TRACKS', data)
          commit('IS_CANCEL_TRACKS_LOADING', false)
        }
      } catch (err) {
        commit('IS_CANCEL_TRACKS_LOADING', false)
        commit('APP_ERROR', err.message)
      }
    },
    UPDATE_CANCEL: async ({ commit, state }, payload) => {
      try {
        commit('IS_EDITING_CANCEL', true)
        const config = { headers: { 'Content-Type': 'application/json' } }
        const { data } = await axios.put(`${payload.url}`, `${payload.body}`, config)
        commit('IS_EDITING_CANCEL', false)
      } catch (err) {
        commit('IS_EDITING_CANCEL', false)
        commit('APP_ERROR', err.message)
      }
    }
  }
}
